
import { defineComponent, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import AuthorInfo from '@/components/organisms/dms/AuthorDetail/AuthorInfo.vue'
import RelatedItems from '@/components/organisms/dms/AuthorDetail/RelatedItems.vue'
import SideMenu from '@/components/organisms/dms/common/SideMenu.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import ToPageTop from '@/components/organisms/dms/common/ToPageTop.vue'
import { postDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'AuthorDetail',
  components: {
    AuthorInfo,
    RelatedItems,
    SideMenu,
    DssIcon,
    ToPageTop,
  },
  setup () {
    const router = useRouter()
    const route = useRoute()

    const authorInfo = ref({
      authorId: '',
      authorName: '',
      authorAliasName: '',
      birthYear: '',
      deathYear: '',
      info: '',
      info2: '',
    })

    const works = ref([])
    const worksNum = ref(0)

    const store = useStore()
    const openinqSearch = store.getters.openinqSearch

    const searchAuthor = async () => {
      const url = `${BASE_URL}/outer/inquiry/_search`
      const body = JSON.stringify({
        authorId: route.query.authorId,
        from: 0,
        keyword: '',
        ndc: '',
        size: 20,
        sortKey: 'issued',
        sortOrder: 'asc',
      })
      const response = await postDataCredential(url, body, true)
      authorInfo.value = {
        authorId: response.data.hits.hits[0]._source.authorId,
        authorName: response.data.hits.hits[0]._source.authorName,
        authorAliasName: response.data.hits.hits[0]._source.authorAliasName,
        birthYear: response.data.hits.hits[0]._source.birthYear,
        deathYear: response.data.hits.hits[0]._source.deathYear,
        info: response.data.hits.hits[0]._source.job ? response.data.hits.hits[0]._source.job : '',
        info2: response.data.hits.hits[0]._source.generation ? response.data.hits.hits[0]._source.generation : '',
      }
      works.value = response.data.hits.hits[0]._source.works.sort((a: any, b: any) => {
        if (a.issued < b.issued) {
          return -1
        } else if (a.issued > b.issued) {
          return 1
        } else {
          return 0
        }
      })
      worksNum.value = Number(response.data.hits.hits[0]._source.worksNum)
    }
    searchAuthor()

    return {
      router,
      authorInfo,
      works,
      worksNum,
      openinqSearch,
    }
  },
})
