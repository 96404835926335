
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import AppButton from '@/components/atoms/AppButton.vue'

export default defineComponent({
  name: 'AuthorInfo',
  components: {
    AppButton,
  },
  props: {
    authorInfo: {
      type: Object,
    },
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()

    const resetCollectAuthorData = async () => {
      await store.dispatch('setCollectAuthorData', {})
    }

    return {
      props,
      router,

      async toAuthorInput () {
        await resetCollectAuthorData()
        await router.push('/dms/author/input?authorId=' + props.authorInfo?.authorId)
      },
    }
  },
})
