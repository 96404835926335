
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import AuthorDetail from '@/components/organisms/dms/AuthorDetail/AuthorDetail.vue'

export default defineComponent({
  components: {
    AuthorDetail,
  },
  setup () {
    const router = useRouter()
    const store = useStore()

    const resetCollectAuthorData = async () => {
      await store.dispatch('setCollectAuthorData', {})
    }
    return {
      router,

      async detail () {
        await resetCollectAuthorData()
        await router.push('/dms/author/input')
      },
    }
  },
})
