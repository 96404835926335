
import { defineComponent, ref, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Table from '@/components/organisms/dms/common/Table.vue'
import AppSelect from '@/components/atoms/AppSelect.vue'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'
import { useI18n } from 'vue-i18n'
import AppIconPlaces from '@/components/atoms/AppIconPlaces.vue'

export default defineComponent({
  name: 'RelatedItems',
  props: {
    works: {
      type: Array,
    },
    worksNum: {
      type: Number,
    },
  },
  components: {
    Table,
    AppSelect,
    AppIconPlaces,
  },
  setup (props, { emit }) {
    const worksList = computed({
      get: () => props.works,
      set: (worksList) =>
        emit('update:works', worksList),
    })
    const router = useRouter()
    const route = useRoute()
    const i18n = useI18n()
    const selected = ref('')
    const sortSelected = ref('issued||asc')
    const sortList = [
      { name: '出版年：昇順', value: 'issued||asc' },
      { name: '出版年：降順', value: 'issued||desc' },
      { name: 'タイトル：昇順', value: 'title||asc' },
      { name: 'タイトル：降順', value: 'title||desc' },
    ]
    const header = ref([
      { key: 'pid', name: '永続的識別子', width: '15%' },
      { key: 'title', name: 'タイトル', width: '11%' },
      { key: 'attribute', name: '役割', width: '9%' },
      { key: 'publicationPlace', name: '出版地', width: '10%' },
      { key: 'publisher', name: '出版者', width: '10%' },
      { key: 'issued', name: '出版年', width: '10%' },
      { key: 'permission', name: '公開状況', width: '18%' },
    ])

    const permissionText = (permission: any) => {
      if (permission !== 'internet' && permission !== 'ooc' && permission !== 'inlibrary') {
        return ''
      }
      return i18n.t(`accessRestriction.${permission}`)
    }

    const permissionType = (permission: any) => permission || ''

    watch(sortSelected, () => {
      const sortKey = sortSelected.value.split('||')[0]
      const sortOrder = sortSelected.value.split('||')[1]
      // eslint-disable-next-line
      worksList.value?.sort((a: any, b: any) => {
        if (a[sortKey] < b[sortKey]) {
          return -1
        } else if (a[sortKey] > b[sortKey]) {
          return 1
        } else {
          return 0
        }
      })
      if (sortOrder === 'desc') {
        // eslint-disable-next-line
        worksList.value?.reverse()
      }
    })

    return {
      props,
      router,
      route,
      selected,
      worksList,
      sortSelected,
      sortList,
      header,
      makeUpdatedPidString,
      permissionText,
      permissionType,
    }
  },
})
